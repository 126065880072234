
  import moment from 'moment'
  import { mapState, mapMutations } from 'vuex'

  export default {
    data() {
      return {
        orders: []
      }
    },
    computed: {
      ...mapState(['user', 'menu', 'defaultMenu', 'location']),
      filteredOrders() {
        return this.orders.slice(0, 3)
      },
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      isConfirmLocationModalButton() {
        if (!this.location) {
          return false
        }

        return this.$cookiz.get("location-confirmed") !== true
      }
    },
    async mounted() {
      try {
        if(this.user){
          const { orders } = await this.$api.getRecentOrders()
          this.orders = orders
        }
      } catch (error) {
        console.error(error)
      }
    },
    methods: {
      ...mapMutations(['setShouldOpenConfirmLocationModal', 'setConfirmLocationModalSection']),
      async handleConfirmLocationModalButton(order) {
        if (this.isConfirmLocationModalButton) {
          await this.$api.createReorder(order.id)
          this.setShouldOpenConfirmLocationModal(true)
          this.setConfirmLocationModalSection('order-again')
        } else {
          this.reorder(order)
        }
      },
      getImages(order) {
        let images = []
        let menu = this.defaultMenu
        if (this.menu) {
          menu = this.menu
        }
        menu.categories.forEach(category => {
          category.products.forEach(menuItem => {
            order.products.forEach(orderItem => {
              if (menuItem.name == orderItem.name) {
                if (menuItem.images) {
                  if (process.env.GW_ENV === 'stage' || process.env.GW_ENV === 'prod') {
                    images.push('//olo-images-live.imgix.net/'+menuItem.images[1].filename)
                  } else {
                    images.push('//olo-images-sandbox.imgix.net/'+menuItem.images[1].filename)
                  }
                }
              }
            })
          })
        })
        return images
      },
      getDate(order) {
        return moment(order.time.placed, 'YYYYMMDD HH:mm').format('MMM. Do')
      },
      getDescription(order) {
        if (order.products.length) {
          let desc = order.products[0].name
          order.products.forEach((product, i) => {
            if (i > 0) {
              desc = desc+', '+product.name
            }
          })
          if (desc.length > 50) {
            desc = desc.substring(0,51)+'...'
          }
          return desc
        }
      },
      async reorder(order) {
        try {
          await this.$api.createReorder(order.id)
          this.$router.push('/order/checkout/')
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
